import {SavingItem} from "../../interfaces/saving";

const calculateDueDate = (startDate: Date, totalValue: number): string => {
    const daysToAdd = Math.floor(totalValue / 100); // Assuming 1 unit of totalValue = 100 days
    const dueDate = new Date(startDate.getTime());
    dueDate.setDate(startDate.getDate() + daysToAdd);
    return dueDate.toISOString();
};
export const data: SavingItem[] = [{
    id: '0',
    label: 'Rainy Day',
    currentValue: 4498.21,
    totalValue: 4498.21,
    icon: '☔',
    date: new Date().toISOString(),
    dueDate: calculateDueDate(new Date(), 4498.21)
},
    {
        id: '1',
        label: 'Vacation',
        currentValue: 168.04,
        totalValue: 3500,
        icon: '🌴',
        date: new Date().toISOString(),
        dueDate: calculateDueDate(new Date(), 3500)
    },
    {
        id: '2',
        label: 'Tattoo',
        currentValue: 217.73,
        totalValue: 300,
        icon: '💀',
        date: new Date().toISOString(),
        dueDate: calculateDueDate(new Date(), 300)
    }];
