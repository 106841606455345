/**
 * Home component is the main component of the application, displaying the saving list and providing options to add new items.
 */
import React, {FC, useState} from 'react';
import "./styles.css";
import SavingList from "../../components/SavingList";
import {addNewSavingItem, generateRandomItem} from "./actions";
import AddNewSavingForm from "../../components/AddNewSavingForm";

const Home: FC = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    /**
     * Adds a random saving item.
     */
    const addRandomItem = () => {
        const item = generateRandomItem();
        addNewSavingItem(item);
    }

    /**
     * Opens the modal for adding a new saving item.
     */
    const openModal = () => {
        setIsOpen(true);
    }

    return (
        <div>
            <h1 className={'text-center'}>Saving List</h1>
            <div className={'d-flex flex-column align-items-center'}>
                <button className={'clear-button'} onClick={openModal} aria-label={'add new saving'}>Add new saving item</button>
                <button className={'clear-button'} onClick={addRandomItem} aria-label={'auto generate'}>Auto-generate new saving item</button>
            </div>
            <SavingList/>
            <div className={`modal ${modalIsOpen ? 'open' : ''}`}>
                <AddNewSavingForm onClose={() => setIsOpen(false)} />
            </div>
        </div>
    )
}
export default Home;
