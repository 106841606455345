// Import necessary dependencies and interfaces
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {data} from "../../shared/static/data";
import {SavingListReducerProps} from "../../interfaces/reducers/savingList";
import {SavingItem} from "../../interfaces/saving";

// Set the initial state of the reducer
const initialState: SavingListReducerProps = {
    list: data,
};

export const savingListReducer = createSlice({
    // Provide a name for the slice
    name: 'savingList',
    // Set the initial state
    initialState,
    // Define the reducer functions
    reducers: {
        // Add a new to-do item to the list
        addSavingItem: (state, action: PayloadAction<SavingItem>) => {
            state.list.unshift(action.payload)
        },
        // move an item within the list
        moveItem: (state, action: PayloadAction<{ sourceIndex: number, destinationIndex: number }>) => {
            const { sourceIndex, destinationIndex } = action.payload;
            const { list } = state;

            const updatedList = [...list];
            const movedItem = updatedList.splice(sourceIndex, 1)[0];
            updatedList.splice(destinationIndex, 0, movedItem);

            state.list = updatedList;
        }
    },
});

// Action creators are generated for each case reducer function
export const {addSavingItem, moveItem} = savingListReducer.actions;

// Export the reducer function itself
export default savingListReducer.reducer;
