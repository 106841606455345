/**
 * SavingListItem component displays a single item in the saving list.
 *
 * Props:
 * - item: The saving item object.
 * - selected: Boolean indicating if the item is selected.
 * - onSelect: Function to handle item selection.
 */
import React, {FC, JSX} from 'react';
import {SavingListItemProps} from "../../interfaces/components/savingListItem";
import "./styles.css";
import {getBalance} from "../../shared/utils/balance";
import moment from "moment";

const SavingListItem: FC<SavingListItemProps> = ({item, selected, onSelect, style}: SavingListItemProps) => {
    /**
     * Calculates the percentage of completion for the item.
     *
     * @returns The completion percentage.
     */
    const getCompletePercentage = () => {
        return (item.currentValue * 100) / item.totalValue;
    }

    /**
     * Formats the date string using moment.js library.
     *
     * @param date - The date string to format.
     * @returns The formatted date string.
     */
    const getDate = (date: string) => {
        return moment(date).format('MMM Do, YYYY');
    }

    const renderItemByStyle = (): JSX.Element => {
        if (style === 1) {
            return <div>
                <div className={'d-flex justify-content-space-between align-items-center'}>
                    <div>
                        <p className={'label'}>{item.label}</p>
                        {item.currentValue === item.totalValue &&
                            <p className={'value'}>${getBalance(item.currentValue)}</p>}
                        {!!item.date &&
                            <p className={'value'}>{getDate(item.date)}</p>}
                    </div>
                    <p className={'icon'}>{item.icon}</p>
                </div>
                {item.currentValue !== item.totalValue && <div>
                    <div className={'progress-container'}>
                        <div className={'active-value'} style={{width: `${getCompletePercentage()}%`}}></div>
                    </div>
                    <div className={'d-flex justify-content-space-between align-items-center'}>
                        <p className={'value'}>${getBalance(item.currentValue)}</p>
                        {+item.currentValue !== +item.totalValue &&
                            <p className={'totalValue'}>${getBalance(item.totalValue)}</p>}
                    </div>
                </div>}
            </div>
        }
        return <div className={'d-flex justify-content-space-between align-items-center'}>
            <p className={'icon'}>{item.icon}</p>
            <div>
                <p className={'label'}>{item.label}</p>
                <p className={'style2Value'}>Due {moment(item.dueDate).format('MMM Do')}</p>
            </div>
            <div>
                <p className={`value text-center ${+item.currentValue === +item.totalValue && 'ready'}`}>
                    {+item.currentValue !== +item.totalValue ? `${getBalance(item.currentValue)}`
                        : `Ready`}</p>
                <p className={'style2Value text-center'}>${getBalance(item.totalValue)}</p>
            </div>
        </div>
    }

    return (
        <div className={`list-item ${selected && 'selected'}`} role={'button'} onClick={() => onSelect(item)}>
            {renderItemByStyle()}
        </div>
    )
}

export default SavingListItem;
