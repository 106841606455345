import React from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Home from "../screens/Home";
import {store} from "../redux/store";
import { Provider } from "react-redux";

const MainRouter = () => {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/" element={<Navigate to="/home"/>}/>
                    <Route path="/home" element={<Home/>}/>
                </Routes>
            </Router>
        </Provider>
    )
}

export default MainRouter;
