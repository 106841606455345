import {store} from '../../redux/store';
import {AddSavingForm, SavingItem} from "../../interfaces/saving";
import {addSavingItem, moveItem} from "./reducer";
import {availableLabels, icons} from "../../shared/utils/enums";

/**
 * Generates a unique ID based on the current timestamp.
 * @returns {string} A unique ID.
 */
const idGenerator = (): string => `${Date.now()}`;


/**
 * Adds a new saving item to the Redux store.
 *
 * @param form - The form data for the saving item.
 */
export const addNewSavingItem = (form: AddSavingForm): void => {
    const item: SavingItem = {
        id: idGenerator(),
        ...form
    };
    store.dispatch(addSavingItem(item));
};

/**
 * Moves a saving item within the list in the Redux store.
 *
 * @param sourceIndex - The index of the item to be moved.
 * @param destinationIndex - The index where the item should be moved to.
 */
export const moveListItem = (sourceIndex: number, destinationIndex: number): void => {
    store.dispatch(moveItem({sourceIndex, destinationIndex}));
};

/**
 * Generates a random saving item form.
 *
 * @returns {AddSavingForm} A randomly generated saving item form.
 */
const getRandomValue = (min: number, max: number) =>
    parseFloat((Math.random() * (max - min) + min).toFixed(2));

/**
 * Generates a random AddSavingForm object with random values for label, icon, current value,
 * total value, and date.
 *
 * @returns {AddSavingForm} A randomly generated AddSavingForm object.
 */
export const generateRandomItem = (): AddSavingForm => {
    const randomLabel = availableLabels[Math.floor(Math.random() * availableLabels.length)];
    const randomIcon = icons[Math.floor(Math.random() * icons.length)];
    const randomCurrentValue = getRandomValue(0, 5000);
    const randomTotalValue = getRandomValue(randomCurrentValue, 5000);
    const randomDate = new Date().toISOString(); // Current date and time
    // Calculate the difference in days based on the total value
    const totalValueDifference = randomTotalValue - randomCurrentValue;
    const daysDifference = Math.floor(totalValueDifference / 100); // Adjust the divisor based on the desired difference in days

    // Calculate the due date by adding the days difference to the random date
    const dueDate = new Date(randomDate);
    dueDate.setDate(dueDate.getDate() + daysDifference);

    return {
        label: randomLabel,
        currentValue: randomCurrentValue,
        totalValue: randomTotalValue,
        icon: randomIcon,
        date: randomDate,
        dueDate: dueDate.toISOString()
    };
}
