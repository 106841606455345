/**
 * AddNewSavingForm component allows the user to add a new saving item.
 *
 * Props:
 * - onClose: A callback function to close the form.
 */
import React, {FC, FormEvent, useEffect, useState} from 'react';
import {AddSavingForm} from "../../interfaces/saving";
import {AddNewSavingFormProps} from "../../interfaces/components/addNewSavingFormProps";
import {icons} from "../../shared/utils/enums";
import {addNewSavingItem} from "../../screens/Home/actions";
import "./styles.css";
import {mainDate} from "../../shared/utils/date";
import moment from "moment";
const mandatoryFields:(keyof AddSavingForm)[] = ['label', 'currentValue', 'totalValue', 'icon', 'dueDate']
const AddNewSavingForm: FC<AddNewSavingFormProps> = ({onClose}) => {
    const [form, setForm] = useState<AddSavingForm>({
        label: '',
        currentValue: 0,
        totalValue: 0,
        icon: '☔',
        date: new Date().toISOString(),
        dueDate: mainDate.getNextWeekDate()
    });
    const [formComplete, setFormComplete] = useState<boolean>(false);

    useEffect(() => {
        let _formComplete = true;
        for (let index = 0; index < mandatoryFields.length; index++) {
            const field = mandatoryFields[index];
            if (!form[field]) {
                setFormComplete(false);
                _formComplete = false;
                break;
            }
        }
        if (+form.currentValue > +form.totalValue) _formComplete = false;
        else if (!mainDate.checkDueDate(form.dueDate)) _formComplete = false;
        setFormComplete(_formComplete);
    }, [form]);
    /**
     * Handles changes in form fields.
     *
     * @param key - The field key.
     * @param event - The change event object.
     */
    const handleChanges = (key: string, event: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        setForm(prev => {
            return {...prev, [key]: event.target.value}
        });
    }

    /**
     * Handles form submission.
     *
     * @param event - The form event object.
     */
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        resetForm();
        addNewSavingItem(form);
        closeModal();
    };

    /**
     * Closes the form and resets the form state.
     */
    const closeModal = () => {
        resetForm();
        onClose();
    }

    /**
     * Resets the form state to its initial values.
     */
    const resetForm = () => {
        setForm({
            label: '',
            currentValue: 0,
            totalValue: 0,
            icon: '☔',
            date: new Date().toISOString(),
            dueDate: mainDate.getNextWeekDate()
        });
        setFormComplete(false);
    }

    return (
        <div className={'modal-container d-flex align-items-center justify-content-center'}>
            <form className={'form-container'} onSubmit={handleSubmit}>
                <h2 className={'text-center'}>Add New Saving Item</h2>
                <p>Label</p>
                <input type={'text'} value={form.label} placeholder={'label'} onChange={(ev) => handleChanges('label', ev)}/>
                <p>Total Value</p>
                <input className={`${+form.currentValue > +form.totalValue && 'error-input'}`} type={'number'} value={form.totalValue} placeholder={'Total Value'} onChange={(ev) => handleChanges('totalValue', ev)}/>
                <p>Current Value</p>
                <input className={`${+form.currentValue > +form.totalValue && 'error-input'}`} type={'number'} value={form.currentValue} placeholder={'Current Value'}
                       onChange={(ev) => handleChanges('currentValue', ev)}/>
                <p>Icon</p>
                <select value={form.icon} className="select-container" onChange={(ev) => handleChanges('icon', ev)} >
                    {icons.map((item: string, idx: number) => <option key={idx} value={item}>{item}</option>)}
                </select>
                <p>Due Date</p>
                <input placeholder={'Due Date'} onClick={(event: any) => event.target.showPicker()}  min={moment().format('YYYY-MM-DD')} className={`${!mainDate.checkDueDate(form.dueDate) && 'error-input'}`} type={'date'}
                       value={moment(form.dueDate).format('YYYY-MM-DD')}
                       onChange={(ev) => handleChanges('dueDate', ev)}/>
                <button disabled={!formComplete} id={'add-new-button'} className={`solid-button big-height ${!formComplete && 'disabled'}`} type={'submit'} aria-label={'create'}>Create</button>
                <button id={'cancel'} className={'clear-button big-height'} type={'button'} onClick={closeModal} aria-label={'cancel'}>Cancel</button>
            </form>
        </div>
    )
}

export default AddNewSavingForm;
