const getNextWeekDate = () => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate()+7).toISOString();
}

const checkDueDate = (secondDate: string): boolean => {
    const first = new Date();
    const second = new Date(secondDate);
    return second > first;
}
// eslint-disable-next-line import/prefer-default-export
export const mainDate = {
    getNextWeekDate,
    checkDueDate
};
