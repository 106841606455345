export const availableLabels = [
    'New Mobile Phone',
    'Gadget Upgrade',
    'Dream Car',
    'Luxury Watch',
    'Home Renovation',
    'Fitness Equipment',
    'Concert Tickets',
    'Designer Handbag',
    'Investment Fund',
    'Education',
    'Business Startup',
    'Camera Gear',
    'Art Collection',
    'Adventure Travel'
];

export const icons = ['☔', '🌴', '💀', '📱', '🚀', '⌚', '🏠', '🎟️', '👜', '💼', '📷', '🎨', '✈️'];
