/**
 * SavingList component displays a list of saving items.
 *
 * Props:
 * - savingList: The saving list object from Redux store.
 */
import React, {FC, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {RootState} from "../../redux/store";
import {SavingListProps} from "../../interfaces/components/savingList";
import SavingListItem from "../SavingListItem";
import {SavingItem} from "../../interfaces/saving";
import "./styles.css";
import {moveListItem} from "../../screens/Home/actions";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faList, faListAlt} from "@fortawesome/free-solid-svg-icons";

const SavingList: FC<SavingListProps> = ({savingList}) => {
    const {list} = savingList;
    const [selected, setSelected] = useState<SavingItem | undefined>(undefined);
    const [selectedStyle, setSelectedStyle] = useState<1 | 2>(1);

    /**
     * Updates the selected item based on user interaction.
     *
     * @param item - The selected saving item.
     */
    const updateSelected = (item: SavingItem) => {
        setSelected(selected?.id === item.id ? undefined : item)
    }

    /**
     * Handles keydown events to move the selected item.
     *
     * @param e - The keyboard event object.
     */
    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'ArrowUp') {
            moveSelectedItem('up');
        } else if (e.key === 'ArrowDown') {
            moveSelectedItem('down');
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selected, list]);

    /**
     * Moves the selected item up or down within the list.
     *
     * @param direction - The direction to move the item ('up' or 'down').
     */
    const moveSelectedItem = (direction: 'up' | 'down') => {
        if (!selected) return;
        const sourceIndex = list.findIndex(item => item.id === selected.id);
        const selectedItemIndex = sourceIndex;
        let destinationIndex;

        if (direction === 'up') {
            destinationIndex = selectedItemIndex - 1;
        } else {
            destinationIndex = selectedItemIndex + 1;
        }
        if (destinationIndex >= list.length || destinationIndex < 0) return;
        moveListItem(sourceIndex, destinationIndex);
    };

    const changeStyle = (style: 1 | 2) => {
        setSelected(undefined);
        setSelectedStyle(style);
    }

    return (
        <div className={'d-flex justify-content-center'}>
            <div className={'list-container'}>
                <div className={'d-flex justify-content-end align-items-center'}>
                    <div className={'icons-container d-flex'}>
                        <button className={`icon-button ${selectedStyle === 1 && 'selected'}`}
                                onClick={() => changeStyle(1)} aria-label={'style 1'}>
                            <FontAwesomeIcon icon={faList}/>
                        </button>
                        <button className={`icon-button ${selectedStyle === 2 && 'selected'}`}
                                onClick={() => changeStyle(2)} aria-label={'style 2'}>
                            <FontAwesomeIcon icon={faListAlt}/>
                        </button>
                    </div>
                </div>
                <div className={'list-container'}>
                    {list.map((item: SavingItem) => <SavingListItem key={item.id} item={item} style={selectedStyle}
                                                                    selected={item.id === selected?.id}
                                                                    onSelect={(item) => updateSelected(item)}/>)}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        savingList: state.savingList
    }
}
export default connect(mapStateToProps)(SavingList);
