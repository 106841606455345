/**
 * This function add comma's for thousands and millions.
 * @param {string | number} x
 * @return {string} balance
 */
export const addCommas = (x: number) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * This function add dollar sign to the current balance
 * @param {string | number} balance
 * @return {string} currentBalance
 */
export const getBalance = (balance: number) => {
  const currentBalance = getFormattedNumber(balance) || 0;
  return addCommas(currentBalance);
};

/**
 * Get number from text string.
 * @param {string} inputNumber
 * @return {number}
 */
export const getFormattedNumber = (inputNumber: number) =>
  inputNumber ? parseFloat(inputNumber?.toString()?.replace(/,/g, '')) : '';
